<template>
    <v-container
            fill-height
            fluid
            grid-list-xl
    >
        <v-layout
        >

            <v-alert v-if="error.message" class=" white--text" color="error">
                <v-icon left color="white">mdi-alert</v-icon>{{error.message}}
            </v-alert>
            <v-skeleton-loader
                    v-if="isLoading"
                    transition="fade-transition"
                    style="width: 100%"
                    type="table"
            >
            </v-skeleton-loader>
            <v-data-table
                    v-else
                    :headers="headers"
                    :items="logistics"
                    sort-by="first_name"
                    class="elevation-1 col-12"
                    :search="search_phrase"
            >
                <DataTableTop slot="top" @search="parseSearch">
                    <v-btn @click="addItem" rounded color="white" class=" green--text"><v-icon left>mdi-truck</v-icon> Add Logistics </v-btn>
                </DataTableTop>

<!--                <template v-slot:item.transaction.final_amount="{item}">-->
<!--                    <v-chip-->
<!--                            class="ma-2 white&#45;&#45;text"-->
<!--                            :color="item.transaction ? 'success' : 'grey'"-->
<!--                    >{{item.transaction ? item.transaction.final_amount : 'unpaid'}}</v-chip>-->
<!--                </template>-->

<!--                <template v-slot:item.status="{ item }">-->
<!--                    <v-chip-->
<!--                            class="ma-2"-->
<!--                            :color="item.status === 'pending' ? 'error' : null"-->
<!--                    >-->
<!--                        {{item.status}}-->
<!--                    </v-chip>-->
<!--                </template>-->

<!--                <template v-slot:item.avatar="{ item }">-->
<!--                    <v-avatar>-->
<!--                        <v-img :src="`${imgHost}imgs/products/${item.product_type.slug}/${item.img_url}`"/>-->
<!--                    </v-avatar>-->
<!--                </template>-->

                <template v-slot:item.capacity="{ item }">
                    <span v-for="capacity in item.capacity" :key="capacity.id">
                        {{capacity.name}}, &nbsp; 
                    </span>
                </template>
                <template v-slot:item.action="{ item }">

                    <v-icon
                            color="success"
                            small
                            class="mr-3"
                            @click="editItem(item)"
                    >
                        edit
                    </v-icon>
                    <v-icon
                            color="error"
                            small
                            @click="deleteItem(item)"
                    >
                        delete
                    </v-icon>
                </template>

            </v-data-table>
            <v-dialog v-model="create_item_dialog" max-width="520px">
                <v-card>
                    <v-toolbar class="primary" dark>
                        <span class="headline">{{ formTitle }}</span>
                        <v-spacer/>
                        <v-btn icon class="mr-3" @click="create_item_dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        @keyup.enter="save"
                                        filled
                                        v-model="editedItem.name" label="Name"></v-text-field>

                                    <v-text-field
                                            @keyup.enter="save"
                                            filled
                                            v-model="editedItem.phone_no" label="Contact Phone"></v-text-field>

                                    <v-text-field
                                            @keyup.enter="save"
                                            filled
                                            v-model="editedItem.location" label="Location"></v-text-field>


                                    <v-text-field
                                            @keyup.enter="save"
                                            filled
                                            v-model="editedItem.type" label="Vehicle Type"></v-text-field>

                                    <v-select
                                        v-model="editedItem.tracking" label="Tracking"
                                        :items="[
                                            {text: 'Yes', value: 'true'},
                                            {text: 'No', value: 'false'},
                                        ]"
                                        filled
                                        item-value="value"
                                        item-text="text"
                                    ></v-select>


                                    <v-select
                                        v-model="editedItem.status" label="Status"
                                        :items="[
                                            {text: 'Active', value: 'active'},
                                            {text: 'Inactive', value: 'inactive'},
                                        ]"
                                        filled
                                        item-value="value"
                                        item-text="text"
                                    ></v-select>

                                    <v-select
                                        v-model="editedItem.insurance" label="Insurance"
                                        :items="[
                                            {text: 'Yes', value: 'true'},
                                            {text: 'No', value: 'false'},
                                        ]"
                                        filled
                                        item-value="value"
                                        item-text="text"
                                    ></v-select>

                                    <v-select
                                        filled
                                        v-model="selectedCapacity"
                                        :items="capacities"
                                        label="Capacities"
                                        multiple
                                        hint=""
                                        persistent-hint
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn rounded color="grey" text @click="close">Cancel</v-btn>
                        <v-btn :disabled="canSave" :loading="is_saving" rounded color="primary" @click="save">
                            Save <v-icon right>mdi-content-save</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- DELETE -->
            <v-dialog
                v-model="deletedItem.show"
                width="500"
            >
                <v-card style="border-raduis: 32px">
                    <v-card-title
                        class="white--text red"
                        primary-title
                    >
                        <v-row
                            justify="space-between"
                            align="start"
                        >
                            <p>Delete Logistics</p>
                            <v-btn icon color="white" @click="deletedItem.show = false">
                                <v-icon >close</v-icon>
                            </v-btn>
                        </v-row>
                    </v-card-title>

                    <v-alert v-if="error.message" color="error">
                        {{error}}
                        <pre>
                            {{deletedItem}}
                        </pre>
                    </v-alert>

                    <v-card-text class=" subtitle-1 pt-5">
                        Deleted Logistics can not be retrieved.
                        Are you sure you want to delete the Logistics?
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            :loading="deleting"
                            color="red"
                            text
                            @click="confirmDelete"
                        >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import Crud from '../../../gql/PrismaCrud.gql'
    import DataTableTop from "../../../components/datatable/DataTableTop";
    export default {
        name: "UsersDataTable",
        components: {DataTableTop},
        data(){
            return {
                selectedCapacity: [],
                capacities: [],
                Capacity: new Crud('logisticCapacity'),
                Logistic : new Crud('logistic'),
                isLoading: false,
                create_item_dialog: false,
                is_saving: false,
                is_update: false,
                search_phrase: '',
                defaultItem: {},
                editedItem: {},
                deletedItem : {},
                deleting: false,
                error: {},
                user: this.$store.getters['auth/getUserData'],
                logistics:  [],
                logisticReturnType: `{
                    id
                    phone_no
                    type
                    insurance
                    location
                    status
                    tracking
                    name
                    capacity{
                        id
                        name
                    }
                }`,
                headers: [
                    { text: 'Name', align: 'left', value: 'name' },
                    { text: 'Phone', align: 'left', value: 'phone_no' },
                    { text: 'Type', align: 'left', value: 'type' },
                    { text: 'Location', align: 'left', value: 'location' },
                    { text: 'Status', align: 'left', value: 'status' },
                    { text: 'Tracking', align: 'left', value: 'tracking' },
                    { text: 'Capacities', align: 'center', value: 'capacity', sortable: false },
                    { text: 'Actions', value: 'action', sortable: false },
                ],
            }
        },
        computed:{
            formTitle () {
                return this.is_update === false ? `New` : `Edit`
            },
            canSave: function () {
                return !(
                    this.editedItem.phone_no &&
                    this.editedItem.name &&
                    this.editedItem.location &&
                    this.editedItem.type &&
                    this.editedItem.status
                )
            }
        },
        methods: {

            parseSearch(search){
                this.search_phrase = search
            },

            getLogistic(){
                this.isLoading = true
                this.Logistic.find( this.logisticReturnType)
                .then(logistics=>{
                    this.logistics = logistics.data
                }).catch(err=>{
                    this.error = err
                }).finally(_=>{
                    this.isLoading = false
                })
            },

            getCapacities(){
                this.Capacity.find(`{ id name }`)
                    .then(res=>{
                        console.log({res})
                        this.capacities = res.data.map(capacitor=>{
                            return { text: capacitor.name, value: capacitor.id }
                        })
                    })
            },

            async updateItem(){
                let updateItem = {...this.editedItem}
        
                let id = updateItem.id
                delete updateItem.__typename
                delete updateItem.id
                
                let editedCapacities = [...this.editedItem.capacity] // [{name id}]
                let selectedIDs = this.selectedCapacity// [id]
                let fetchCapacities = this.capacities // [{text value}]
                let isExists = {}
                let connect = []
                let disconnect = []

                //Check Exists
                fetchCapacities.forEach(fc => {
                    for (let index = 0; index < editedCapacities.length; index++) {
                        const edc = editedCapacities[index];
                        if(fc.value == edc.id && !isExists[edc.id]){
                            isExists[edc.id] = edc.name
                        }
                    }
                });
                //Check Connect

                fetchCapacities.forEach(fc => {
                    for (let index = 0; index < selectedIDs.length; index++) {
                        const sid = selectedIDs[index];
                        if(fc.value == sid && !isExists[sid]){
                            connect.push({id: sid})
                        }
                    }
                })

                //Check Disconnect
                Object.keys(isExists).forEach((ie, i) => {
                    for (let fc_in = 0; fc_in < fetchCapacities.length; fc_in++) {
                        const fc = fetchCapacities[fc_in]
                        if(ie == fc.value){
                            // if selected is empty but there are any existed id
                            // set all to disconnect
                            let isDisconnect = true
                            
                            // if selected does not contain an existing id
                            // set it to disconnect

                            for (let index = 0; index < selectedIDs.length; index++) {
                                const sid = selectedIDs[index]
                                if(sid == ie){
                                    isDisconnect = false
                                }  
                            }

                            if(isDisconnect){
                                disconnect.push({id: ie})
                            }
                        }
                    }
                })

                updateItem.capacity = {
                    disconnect,
                    connect
                }
                console.log(updateItem.capacity)
                // return async function(){}
                return this.Logistic.update(this.logisticReturnType, {
                    where: {
                        id
                    },
                    data: updateItem
                }).then(res=>{
                    this.getLogistic()
                }).catch(err=>{
                    console.log({editedItem})
                    console.error(err)
                })
            },
            
            createItem(){
                let createItem = {...this.editedItem}
                createItem.capacity = {
                    connect: []
                }
                this.selectedCapacity.forEach(id=>{
                    createItem.capacity.connect.push({id})
                })
                createItem.user = {
                    connect: {id: this.user.id}
                }

                console.log({createItem})
                return this.Logistic.insert(this.logisticReturnType, {
                    data: createItem
                }).then(res=>{
                    this.getLogistic()
                })
            },

            deleteItem(item){
                this.deletedItem = item
                this.deletedItem.show = true
            },
            
            confirmDelete(){
                this.deleting = true
                return this.Logistic.remove(`{id}`, {where: {id: this.deletedItem.id}})
                    .then(res=>{
                        this.getLogistic()
                        this.deletedItem = {}
                    })
                    .catch(err=>{
                        console.error(err)
                    }).finally(_=>{
                        this.deleting = false
                    })
            },
            save () {
                this.is_saving = true
                if (this.is_update === true) {
                    this.updateItem().finally(_=>{
                        this.close()
                        this.is_saving = false
                    })
                } else {
                    this.createItem().finally(_=>{
                        this.editedItem = {}
                        this.close()
                    })
                }
            },

            close () {
                this.create_item_dialog = false
                setTimeout(() => {
                    this.editedItem = this.defaultItem
                    this.is_update = false
                    this.selectedCapacity = []

                }, 300)
            },

            addItem(){
                this.is_update = false
                this.editedItem = {}
                this.create_item_dialog = true
            },

            editItem(item){
                this.is_update = true
                this.editedItem =  {...item}
                this.create_item_dialog = true
                this.selectedCapacity = item.capacity.map(capacity=>{
                    return capacity.id
                })
            }
        },
        created(){
            this.getLogistic()
            this.getCapacities()
        }
    }
</script>
